import {DomainStore, fetchModels} from 'shared/store';
import {computed, observable, action} from 'mobx';
import {Goal, Metric} from 'stores/performance_goals';
import {auth, t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import {Employee} from 'stores/employees';
import * as queryString from 'querystring';
import _ from 'lodash';

class EditGoalContainerState {
  store = new DomainStore();
  match;
  history;

  @observable employee;
  @observable goal;
  @observable editingMetric;
  @observable deleteMetricModalOpen = false;
  @observable availableParentGoals = [];
  @observable availableAssignees = [];
  @observable errors = {};

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const params = queryString.parse(this.history.location.search.replace('?', ''));

    if (this.match.params.goalId) {
      await this.initExistingGoal();
    } else if (params.duplicate_goal_id) {
      await this.initDuplicateGoal(params.duplicate_goal_id);
    } else {
      await this.initNewGoal();
    }

    await this.fetchAvailableParentGoals();
  }

  async initExistingGoal() {
    await this.store._compose([
      endpoints.PERFORMANCE_GOALS.GOAL.with(this.match.params.goalId),
      endpoints.PERFORMANCE_GOALS.EMPLOYEES
    ]);

    this.goal = new Goal(this.store._getSingle(types.PERFORMANCE_GOALS.GOAL, {id: this.match.params.goalId}));
    this.availableAssignees = this.store._getAll(types.EMPLOYEE, Employee);
  }

  async initDuplicateGoal(goalId) {
    await this.store._compose([
      endpoints.PERFORMANCE_GOALS.GOAL.DUPLICATE.with(goalId),
      endpoints.PERFORMANCE_GOALS.EMPLOYEES
    ]);

    this.goal = new Goal(this.store._getSingle(types.PERFORMANCE_GOALS.GOAL));
    this.availableAssignees = this.store._getAll(types.EMPLOYEE, Employee);
  }

  @action async initNewGoal() {
    await this.store._compose([endpoints.PERFORMANCE_GOALS.EMPLOYEES]);

    this.goal = new Goal();
    this.availableAssignees = this.store._getAll(types.EMPLOYEE, Employee);

    const employee = this.store._getSingle(types.EMPLOYEE, { id: auth.employee.id});
    if (employee) {
      this.goal.employee = new Employee(employee);
    }

    this.addMetric();
  }

  @action async fetchAvailableParentGoals() {
    const parentGoals = await fetchModels(
      endpoints.PERFORMANCE_GOALS.PARENT_GOALS_FOR_GOAL.with(this.match.params.goalId),
      types.PERFORMANCE_GOALS.GOAL
    );
    this.availableParentGoals = _.sortBy(parentGoals.map(model => new Goal(model)), 'title');
  }

  @action async save() {
    if (this.goal.isNew) {
      await this.createGoal();
    } else {
      await this.patchGoal();
    }
  }

  @action async createGoal() {
    const {model, errors} = await this.store.post(
      endpoints.PERFORMANCE_GOALS.GOALS,
      types.PERFORMANCE_GOALS.GOAL,
      this.goal
    );

    this.errors = errors;
    if (model) {
      this.history.push({
        pathname: `/view/${model.id}`,
        state: {
          ...this.history.location.state,
          fromEditPage: true
        }
      });
      successAlert(t('goals.Goal created'));
    }
  }

  @action async patchGoal() {
    const {model, errors} = await this.store.patch(this.goal);

    this.errors = errors;
    if (model) {
      this.history.push({
        pathname: `/view/${this.goal.id}`,
        state: {
          ...this.history.location.state,
          fromEditPage: true
        }
      });
      successAlert(t('goals.Goal updated'));
    }
  }

  @action setParentGoal(parentGoal) {
    if (parentGoal) {
      this.resetDatesToParentGoal(parentGoal);
    }
    this.updateGoal({parentGoal: parentGoal});
  }

  @action async updateEmployee(employee) {
    this.updateGoal({employee: employee});
  }

  @action updateGoal(updatedAttributes) {
    this.goal.merge(updatedAttributes);
  }

  @action resetDatesToParentGoal(parentGoal) {
    if (!this.goal.startDate || this.goal.startDate > parentGoal.endDate) {
      this.goal.startDate = parentGoal.startDate;
    } else {
      this.goal.startDate = _.max([parentGoal.startDate, this.goal.startDate]);
    }

    if (!this.goal.endDate || this.goal.endDate < parentGoal.startDate) {
      this.goal.endDate = parentGoal.endDate;
    } else {
      this.goal.endDate = _.min(_.compact([parentGoal.endDate, this.goal.endDate]));
    }
  }

  @action addMetric() {
    const newMetric = new Metric({
      name: '',
      order: this.goal.metrics.length,
      metricType: 'percentage',
      startValue: 0,
      targetValue: 100
    });
    this.goal.metrics.push(newMetric);
  }

  @action onMetricDelete(metric) {
    this.editingMetric = metric;
    if (!metric.isNew) {
      this.deleteMetricModalOpen = true;
    } else {
      this.deleteMetric();
    }
  }

  @action closeDeleteMetricModal() {
    this.editingMetric = null;
    this.deleteMetricModalOpen = false;
  }

  @action deleteMetric() {
    this.goal.metrics.remove(this.editingMetric);
    this.closeDeleteMetricModal();
  }

  @computed get title() {
    return this.goal.isNew ? t('goals.Create Goal') : t('goals.Edit Goal');
  }

  @computed get isEmployee() {
    if (!this.goal.employee) return false;

    return this.goal.employee.id === auth.employee.id;
  }

  @computed get sharedWith() {
    if (!this.goal.employee) return null;

    if (this.isEmployee) {
      return t('goals.SHARED_WITH_MANAGER');
    } else {
      return t('goals.SHARED_WITH_EMPLOYEE',  { employeeName: this.goal.employee.name });
    }
  }
}

export default EditGoalContainerState;
