import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, Input, TextArea, DatePicker, ObjectSelect2} from 'components';

const GoalForm = observer(({uiState}) => {
  const {goal, sharedWith, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={goal.titleView} />
      <FormLayout>
        <Input
          value={goal.title}
          onChange={e => uiState.updateGoal({title: e.target.value})}
          label='goals.Title'
          placeholder='goals.e.g. Marketing Performance Targets'
          errorMessage={errors.title}
        />
        <TextArea
          value={goal.description}
          onChange={e => uiState.updateGoal({description: e.target.value})}
          label='goals.Description'
          placeholder='goals.Increase overall reach, engagement, and conversion through targeted marketing strategies.'
          errorMessage={errors.description}
          minRows={2}
        />
        <ObjectSelect2
          label='goals.Assignee'
          value={goal.employee}
          searchable={true}
          onChange={value => uiState.updateEmployee(value)}
          errorMessage={errors.employee}
          items={uiState.availableAssignees}
        />
        <ObjectSelect2
          label='goals.Parent Goal'
          value={goal.parentGoal}
          display='titleWithEmployeeName'
          optional={true}
          searchable={true}
          onChange={value => uiState.setParentGoal(value)}
          allowClear={true}
          errorMessage={errors.parentGoal}
          items={uiState.availableParentGoals}
          helpMessage='goals.PARENT_GOAL_DESCRIPTION'
        />
        <FormLayout.Group>
          <DatePicker
            value={goal.startDate}
            onChange={date => uiState.updateGoal({startDate: date})}
            label='goals.Start Date'
            errorMessage={errors.startDate}
          />
          <DatePicker
            value={goal.endDate}
            onChange={date => uiState.updateGoal({endDate: date})}
            label='goals.End Date'
            errorMessage={errors.endDate}
          />
        </FormLayout.Group>
      </FormLayout>
      {sharedWith && <div className='jumbo'>
        <i className='material-icons h3 align-middle waterloo mr1'>{'visibility'}</i>
        {sharedWith}
      </div>}
    </Panel>
  );
});

export default GoalForm;
