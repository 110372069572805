import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table, ProgressIndicator, ActionLink} from 'components';
import {t} from 'shared/core';
import _ from 'lodash';

const Subgoal = observer(({model, uiState}) => {
  return (
    <div className='flex align-items-middle justify-content-between'>
      <div className='col-4'>
        {model.canViewFullDetails ? <ActionLink onClick={() => uiState.goToGoal(model)}>{model.title}</ActionLink> : model.title}
      </div>
      <div className='flex align-items-middle col-8 md-col-6'>
        <div className='flex-grow'>
          <div className='flex'>
            <div className='col-5 mr2 right-align'>{model.formattedCompleteness}</div>
            <div className='col-7 pr1'>
              <ProgressIndicator items={model.progressBarItems}/>
            </div>
          </div>
        </div>
        <div style={{width: 24}}/>
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    component: Subgoal,
    width: 12
  }
];

const LOCALIZATION = {
  emptyState: 'goals.No subgoals to display'
};

const SubgoalDetails = observer(({uiState}) => {
  const {goal} = uiState;

  if (_.isEmpty(goal.subgoals)) return null;

  return (
    <Panel>
      <Panel.Header title={t('goals.Subgoals')}/>
      <Panel.Stack>
        <Table
          uiState={uiState}
          models={goal.sortedSubgoals}
          columns={COLUMNS}
          localization={LOCALIZATION}
          showLinks={false}
          showHeaders={false}
        />
      </Panel.Stack>
    </Panel>
  );
});

export default SubgoalDetails;
