import {action, observable, computed} from 'mobx';
import {Goal} from 'stores/performance_goals';
import _ from 'lodash';

class GoalViewModel {
  @observable data;

  @observable isExpanded = false;
  @observable hasFetchedSubgoals = false;

  constructor(data) {
    this.data = new Goal(data);
  }

  @action expand() {
    this.isExpanded = true;
  }

  @action collapse() {
    this.isExpanded = false;
    this.subgoalViewModels.forEach(subgoal => subgoal.collapse());
  }

  @computed get subgoalViewModels() {
    return this.data.sortedSubgoals.map(subgoal => new GoalViewModel(subgoal));
  }

  showBorder(metricIndex) {
    return metricIndex !== this.data.metrics.length - 1 || this.data.subgoals.length > 0;
  }
}

export default GoalViewModel;
