import {DomainStore, deleteModel} from 'shared/store';
import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import {types, endpoints} from 'shared/core';
import {Goal} from 'stores/performance_goals';

class GoalListState {
  match;
  history;
  canCreateGoals;
  agent;

  @observable title;
  @observable selectedGoal = {};
  @observable errors = {};
  @observable interactiveContextState = {};

  receiveProps({match, history, canCreateGoals}) {
    this.match = match;
    this.history = history;
    this.canCreateGoals = canCreateGoals;
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  goToNewGoalPage() {
    if (this.match.params.employeeId) {
      this.history.push(`${this.match.url}/new`);
    } else {
      this.history.push('/new');
    }
  }

  goToDuplicateGoalPage(goal) {
    this.history.push(`/new?duplicate_goal_id=${goal.id}`);
  }

  @action async deleteGoal() {
    await deleteModel(this.selectedGoal);
    await this.agent.refresh();
    this.closeDeleteGoalModal();
  }

  @action async goToGoal(goal) {
    await this.history.push(`/view/${goal.id}`);
  }

  @action updateInteractiveContextState(interactiveContextState) {
    this.interactiveContextState = interactiveContextState;
  }

  @action async toggleExpandGoal(goalViewModel) {
    if (!goalViewModel.data.canViewFullDetails) return;

    if (goalViewModel.isExpanded) {
      goalViewModel.collapse();
    } else {
      goalViewModel.expand();
      if (!goalViewModel.hasFetchedSubgoals) {
        const store = new DomainStore();
        await store._compose([endpoints.PERFORMANCE_GOALS.GOAL.SUBGOALS.with(goalViewModel.data.id)]);
        goalViewModel.data.subgoals = store._getAll(types.PERFORMANCE_GOALS.GOAL, Goal);
        goalViewModel.hasFetchedSubgoals = true;
      }
    }
  }

  @computed get canViewNestedGoals() {
    const search = this.interactiveContextState.filter.get('search') || '';
    const goal_status = this.interactiveContextState.filter.get('goal_status') || [];

    const isSearchEmpty = _.isEmpty(search);
    const isGoalStatusEmpty = _.isEmpty(goal_status);
    const isGoalStatusAll = goal_status.includes('all');

    return isSearchEmpty && (isGoalStatusEmpty || isGoalStatusAll);
  }

  customLinksFor(model) {
    const links = [];

    if (model.canPatch) {
      links.push(
        {
          order: 0,
          text: 'Edit',
          action: () => this.history.push(`/edit/${model.id}`)
        }
      );
    }

    if (model.hasLink('duplicate')) {
      links.push(
        {
          order: 1,
          text: 'goals.Duplicate',
          action: goal => this.goToDuplicateGoalPage(goal)
        }
      );
    }

    if (model.canDelete) {
      links.push(
        {
          order: 2,
          text: 'Remove',
          action: () => this.openDeleteGoalModal(model)
        }
      );
    }

    return links;
  }
}

export default GoalListState;
