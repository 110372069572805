import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {auth, endpoints, types} from 'shared/core';
import {TimeTrackingPayPeriod, TimeTrackingProject} from 'stores/time_tracking';
import moment from 'moment';

class MyTimesheetState {
  @observable payPeriods = [];
  @observable projects = [];
  @observable selectedPayPeriod;
  @observable employeeId = _.get(auth, 'employee.id');
  store = new DomainStore();

  @action async load() {
    await this.store._compose([
      endpoints.TIME_TRACKING.PAY_PERIODS.FOR_EMPLOYEE.with(this.employeeId),
      endpoints.TIME_TRACKING.PROJECTS.FOR_EMPLOYEE
    ]);

    this.payPeriods = _.orderBy(this.store._getAll(types.TIME_TRACKING.PAY_PERIOD).map(payPeriod => new TimeTrackingPayPeriod(payPeriod)), 'startDate', 'desc');
    this.projects = this.store._getAll(types.TIME_TRACKING.PROJECT).map(project => new TimeTrackingProject(project));

    if (_.isEmpty(this.payPeriods)) {
      window.location = '/time_tracking';
    } else {
      this.selectedPayPeriod = this.currentPayPeriod;
    } 
  }

  @action selectPayPeriod(payPeriod) {
    this.selectedPayPeriod = payPeriod;
  }

  @computed get sortedPayPeriods() {
    return _.orderBy(this.payPeriods, 'startDate', 'desc');
  }

  @computed get currentPayPeriod() {
    return _.find(this.sortedPayPeriods, payPeriod => moment(payPeriod.startDate).isBefore(moment())) ||
      this.sortedPayPeriods[0];
  }
}

export default MyTimesheetState;
