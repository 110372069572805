import moment from 'moment';
import {auth, t} from 'shared/core';

export const relativeCalendar = (myMoment, now) => {
  var dayDiff = myMoment.diff(now, 'days', true);
  var weekDiff = myMoment.week() - now.week();

  var retVal =  dayDiff < 0 && dayDiff >= -1 ? 'lastDay' :
                dayDiff < 1 && dayDiff >= 0 ? 'sameDay' :
                dayDiff < 2 && dayDiff >= 1 ? 'nextDay' :
                weekDiff === 0 && dayDiff > 0 ? 'sameWeek' :
                weekDiff === 1 ? 'nextWeek' :
                weekDiff === -1 ? 'lastWeek' :
                'sameElse';
  return retVal;
};

export const dateFormatter = (date) => {
  if (!moment(date).isValid()) return null;
  const momentDate = moment(date);
  momentDate.locale(auth.locale);

  return momentDate.calendar(null, {
    sameDay: `[${t('components.dates.Today')}]`,
    nextDay: `[${t('components.dates.Tomorrow')}]`,
    lastDay: `[${t('components.dates.Yesterday')}]`,
    nextWeek: 'll',
    lastWeek: 'll',
    sameWeek: 'll',
    sameElse: 'll'
  });
};

export const calendarDate = (date, options = {}) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;
  if (options.dateFilterFormat === 'month') {
    return momentDate.format(`MMMM YYYY`);
  }
  return momentDate.format(`${options.includeDayOfWeek ? 'dddd, ' : ''}LL`);
};

export const dayOfWeek = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;

  return momentDate.format('ddd, MMM D');
};

export const rangeDate = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;
  return momentDate.format('ll');
};

export const relativeRangeDate = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;

  return momentDate.calendar(null, {
    sameDay: `[${t('components.dates.Today')}]`,
    nextDay: `[${t('components.dates.Tomorrow on')}] ll`,
    lastDay: `[${t('components.dates.Yesterday')}]`,
    nextWeek: `[${t('components.dates.Next week on')}] ll`,
    sameWeek: `[${t('components.dates.This week on')}] ll`,
    lastWeek: `ll`,
    sameElse: `ll`
  });
};

export const daysFromNow = (date) => {
  const momentDate = moment(date);

  return momentDate.diff(moment().startOf('day'), 'days');
};

export const dateSpan = (startDate, endDate, includeYears = false) => {
  if (!startDate || !endDate) return null;
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) return null;

  const momentStartDate = moment(startDate);
  momentStartDate.locale(auth.locale);

  const format = includeYears ? 'MMM D, YYYY' : 'MMM D';

  if (startDate === endDate) {
    return momentStartDate.format(format);
  }

  const momentEndDate = moment(endDate);
  momentEndDate.locale(auth.locale);

  return t('components.dates.DATE_SPAN', {
    startDate: momentStartDate.format(format), endDate: momentEndDate.format(format)
  });
};

export const timestampDate = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;

  return momentDate.calendar(null, {
    sameDay: `[${t('components.dates.Today at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`,
    nextDay: `[${t('components.dates.Tomorrow at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`,
    lastDay: `[${t('components.dates.Yesterday at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`,
    sameWeek: `dddd [${t('components.dates.at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`,
    nextWeek: `D MMM YYYY [${t('components.dates.at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`,
    lastWeek: `D MMM YYYY [${t('components.dates.at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`,
    sameElse: `D MMM YYYY [${t('components.dates.at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`
  });
};

export const absoluteDateFormatter = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!moment(date).isValid()) return null;

  return momentDate.format('ll');
};

export const absoluteTimestampDate = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;

  return momentDate.format(`ll [${t('components.dates.at')}] ${t('components.dates.TIMESTAMP_FORMAT')}`);
};

export const formattedTimestampDate = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;

  return momentDate.format(`${t('components.dates.TIMESTAMP_FORMAT')} [${t('components.dates.on')}] dddd, MMMM Do YYYY`);
};

export const longTimestampDate = (date) => {
  const momentDate = moment(date);
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;
  return momentDate.format('LL LTS');
};

export const monthAndDay = (month, day) => {
  const momentDate = moment(new Date(2024, month - 1, day));
  momentDate.locale(auth.locale);
  if (!momentDate.isValid()) return null;

  return momentDate.format(t('components.dates.MONTH_AND_DAY_FORMAT'));
};
